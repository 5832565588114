// Loading spinner added by Bootstrap theme's modal.js.
.loading-spinner {
  margin: 0 auto !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  .progress {
    margin: 0;
  }
}

// Bootstrap theme's user.js moves stuff around on password elements, but for
// some reason misses this straggler label.
.form-type-password-confirm > .control-label {
  display: none;
}

// Remove extra fieldset padding within vertical tabs.
.tabs-left.vertical-tabs > .tab-content {
  padding: 0;
}

// Modal footers.
.ctools-modal-dialog .modal-footer {
  position: relative;
  background: white;
  border-radius: 0 0 @border-radius-base @border-radius-base;
  z-index: 5;
  .form-actions {
    text-align: left;
    margin: 0;
  }
}

// Our Bootstrap patch moves form actions to modal footers, so let's tighten up
// the styling a bit.
// See https://www.drupal.org/node/2637314#comment-10799826.
#modal-content > form .modal-body > div > div {
  // Remove margin from last child of a few things.
  .panel:last-child, .tabbable:last-child {
    margin-bottom: 0;
  }

  // Firefox will not render the bottom padding of .modal-body, so add margin.
  @-moz-document url-prefix() {
    margin-bottom: 15px;
  }
}

// Force size for tab summaries, in case of large base font sizes.
.tabs-below, .tabs-left, .tabs-right {
  > .nav-tabs .summary {
    font-size: 14px;
  }
}
